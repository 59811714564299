import React from "react";
import "./policies.css";
const Policies = () => {
  return (
    <div className="box-container">
      <div className="policies-container">
        <div className="plicy-head">تفاصيــــل العمل</div>
        <div className="policy-header">
          الموضوع بأختصار هو عباره عن تسويق لكل المنتجات المتوفره فى مخازننا ، و
          تقوم بحجز اوردرات للعملاء اللى انتا وصلتلهم و الحجز بيكون من خلال
          الويب سايت..
        </div>
        <div style={{ marginTop: "1rem" }}>
          و هنا تنتهى مهمة المسوق.. و نحن نقوم بعمل باقي المراحل..
        </div>
        <div style={{ marginTop: "1rem" }}>
          <ol className="list-decimal">
            <li>من تغليف</li>
            <li>توصيل الاوردر</li>
            <li>متابعة العميل</li>
            <li>تحصيل حساب الاوردر من العميل</li>
          </ol>
        </div>

        <div style={{ marginTop: "1rem", color: "red" }}>
          وبعد التحصيل يتم ارسال العمولة الخاصة بك مثال للتوضيح :
        </div>

        <div style={{ marginTop: "1rem" }}>
          ( نفترض انك حجزت اوردر على قطعه معينه سعر جملتها 500ج و اتفقت مع عميلك
          ان السعر 600ج ، و تم توصيل الاوردر للعميل ، هنا تحصل على عمولتك 100ج )
        </div>

        <div style={{ marginTop: "2rem" }}>
          <ul className="list-disc">
            <li>
              بنوفرلك المنتجات بأسعار الجمله و حضرتك تقدر تضيف هامش الربح
              المناسب لك...
            </li>
            <li>
              تقدر تسوق للمنتجات عن طريق بيدج او اعلانات مموله على اى منصه من
              منصات السوشيال ميديا ، او تعرض على جيمات او مدربين داخل جيمات او
              تجمع داته للناس المهتمه بمجال المكملات فى قناة واتس او
              تليجرام...الخ
            </li>
            <li> بنوفرلك دعم دائم تقدر تستفسر عن اى حاجه من خلال الواتس </li>
            <li>
              {" "}
              ميتنج على زوم بشكل مستمر لتقوية مهاراتك و الاجابه عن استفساراتك
            </li>
            <li>توفير خدمة عملاء للمسوقين للرد عن اى استفسار او استشاره</li>
            <li> هنبعتلك لسته لكل المنتجات المتاحه بأسعار الجمله</li>
          </ul>
        </div>

        <div
          style={{
            marginTop: "3rem",
            color: "red",
            textDecoration: "underline",
          }}
        >
          سياسات يجب معرفتها قبل البدأ
        </div>
        <div style={{ marginTop: "1rem" }}>
          <ol className="list-decimal">
            <li>
              يتم شحن الاوردرات يومياً الساعه 5 مساءً و اى اوردر بعد 5 يتم شحنه
              فى اليوم المقبل
            </li>
            <li>
              مدة التوصيل داخل القاهره و الجيزه 24 ساعه ( يوم ) من وقت الشحن و
              باقي المحافظات يومين ، و يوم الجمعه اجازه فى جميع شركات الشحن
            </li>
            <li>
              متاح لك ان تستفسر عن موعد وصول الاوردر اذا تجاوز المده المحدده فقط
              لا غير
            </li>
            <li>يتم الاتفاق على موعد اسبوعى لأرسال العموله</li>
            <li>
              فى حالة الغاء الاوردر بعد شحنه او رفض العميل الاستلام تتحمل انت
              رسوم الشحن
            </li>
            <li>
              يفضل ان ترسل لنا كروت شخصيه للصفحه الخاصه بك لنرسالها مع اوردراتك
              مما يجعل عميلك عميل دائم و يحتفظ برقمك و بشئ يربطه بصفحتك
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default Policies;
