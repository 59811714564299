import React from "react";
import "./footer.css";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import warshtyLogo2 from "../../assets/Abdo_Logo.png";
import CopyrightOutlinedIcon from "@mui/icons-material/CopyrightOutlined";
import { useTranslation } from "react-i18next";
import MessageImg from "../../assets/message.png";
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const Footer = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  return (
    <div className="footer">
      <div className="footer_section">
        <div className="footer_section-column">
          <div className="footer-logo_container">
            <img
              src={warshtyLogo2}
              className="footer-logo"
              alt="عبدالرحمن ستورز"
            />
            <p className="footer__text text-white mt-3">
              شريكك في رحلة كفاحك الذي يوفر لك كل ماتحتاجه في عالم
              المكملات الغذائية ومنتجات اللياقة البدنية..
            </p>
          </div>
        </div>
        <div className="footer_section-column">
          <div className="footer_row">
            <MailOutlineIcon />
            <div className="address">info@abdo.com</div>
          </div>
          <div className="footer_row">
            <LocalPhoneOutlinedIcon />
            <div className="phone-number">01115223322</div>
          </div>

          <div></div>
        </div>
      </div>

      <div className="rights">
        <div className="copy-rights">
          {t("footer_rights")} <CopyrightOutlinedIcon /> 2023
        </div>
        <div className="rights-right">
          <div>عنا</div>
          <div>المنتجات</div>
          <div>هل انت مسوق ؟</div>
          <div>الاسئلة الشائعة</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
