import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./newProducts.css";
import ProductCard from "../productCard/productCard";
import axios from "axios";
import { config } from "../../config";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";

const NewProducts = () => {
  const [newProducts, setNewProducts] = useState([]);
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const accessToken = Cookies.get("jwt");
  let user = null;

  if (accessToken) {
    try {
      user = jwtDecode(accessToken);
    } catch (error) {
      //console.error("Invalid token:", error);
    }
  } else {
    //console.log("No token found");
  }

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: 1,
    },
  };
  useEffect(() => {
    axios.get(config.baseURL + "Products/getNewProducts").then((response) => {
      setNewProducts(response.data);
    });
  }, []);
  return (
    <div className="newProducts_section">
      <h2 className="newProducts_header">{t("new_products")}</h2>

      {newProducts.length ? (
        <Carousel
          responsive={responsive}
          infinite
          autoPlay
          autoPlaySpeed={1500}
        >
          {newProducts
            ? newProducts.map((product) => (
                <ProductCard
                  name={product.name}
                  price={
                    user
                      ? +user.userType === 1
                        ? product.price
                        : product.marketer_price
                      : product.price
                  }
                  image={product.image}
                  image_type={product.image_type}
                  id={product.internal_code}
                />
              ))
            : null}
        </Carousel>
      ) : <div style={{ textAlign: "center" }}>
      <CircularProgress />
    </div>}
    </div>
  );
};

export default NewProducts;
