import axios from "axios";
import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  FETCH_PRODUCT_IMAGES_SUCCESS,
  UPDATE_CART_ITEM_IMAGES,
  RESET_CART_ITEMS,
} from "./actionTypes";

import { config } from "../../config";

export const addToCart = (product) => {
  return (dispatch, getState) => {
    dispatch({
      type: ADD_TO_CART,
      payload: product,
    });

    // Update localStorage whenever the cart changes
    sessionStorage.setItem("cart", JSON.stringify(getState().cart));
  };
};

export const removeFromCart = (productId) => {
  return (dispatch, getState) => {
    dispatch({
      type: REMOVE_FROM_CART,
      payload: productId,
    });

    // Update localStorage whenever the cart changes
    sessionStorage.setItem("cart", JSON.stringify(getState().cart));
  };
};

export const restCartItems = () => {
  return (dispatch, getState) => {
    dispatch({
      type: RESET_CART_ITEMS,
    })
    sessionStorage.setItem("cart", JSON.stringify(getState().cart));
  }
  
}
export const fetchProductImages = (ids) => {
  const arrayAsString = ids.join(',');
  //console.log(arrayAsString)
  return (dispatch) => {
    
    axios
      .get(`${config.baseURL + 'Products/getImages'}`, {
        params: {
          ids: arrayAsString
        }
      })
      .then((response) => {
        dispatch({
          type: FETCH_PRODUCT_IMAGES_SUCCESS,
          payload: response.data,
        });

        //console.log(response.data)
        // Dispatch action to update cart items with images
        dispatch(updateCartItemImages(response.data));
      })
      .catch((error) => {
        console.error('Error fetching product images:', error);
      });
  };
};

export const updateCartItemImages = (images) => ({
  type: UPDATE_CART_ITEM_IMAGES,
  payload: images,
});
