export const CalculateCommission = (arr) => {
  let totalFinal = 0;
  let Total = 0;

  if (arr.length) {
    arr.map((item) => (totalFinal += +item.finalPrice));
    arr.map((item) => (Total += +item.price));
  }

  return totalFinal - Total;
};
