import React, { useState } from "react";
import "./banner.css"; // External CSS file for styling
import bannerImg from "../../assets/image 10.png";
import categoryImg from "../../assets/tabler_category.png";
import searchImg from "../../assets/search-line.png";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';

const Banner = ({ categories }) => {
  const [category, setCategory] = useState(null);
  const [productName, setProductName] = useState();
  const navigate = useNavigate();
  
  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  return (
    <div className="banner-section">
      {/* Banner Section */}
      <div className="banner-content">
        <img src={bannerImg} alt="Banner" className="banner-image" />
        <div className="overlay">
          <h1 className="banner-title">ABD-ELRAHMAN SUPPLEMENTS</h1>
        </div>
      </div>

      {/* Search Section */}
      <div className="search-bar" dir="rtl">
        <div className="search-container">
          {/* Dropdown */}
          {/* <div className="category-dropdown">
            <img src={categoryImg} alt="Category" />
            <select
              value={category}
              onChange={handleChange}
              className="dropdown-select2"
            >
              <option value="" disabled selected>
                التصنيف
              </option>
              {categories
                ? categories.map((category) => (
                    <option value={category.name}>{category.name}</option>
                  ))
                : null}
            </select>
          </div> */}
          {/* Search Input */}
          <input
            type="text"
            className="search-input"
            placeholder="مثال للبحث: واي بروتين حجم 1 كيلو جرام"
            onChange={(e) => setProductName(e.target.value)}
          />

          {/* Search Button */}
          <button
            className="banner-search-button"
            onClick={() => {
              navigate(`/searchMobile/${productName}`);
            }}
          >
            {/* <img src={searchImg} alt="search icon" className="search-icon" /> */}
            <SearchIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Banner;
