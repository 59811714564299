import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  FETCH_PRODUCT_IMAGES_SUCCESS,
  RESET_CART_ITEMS,
} from "./actionTypes";

const initialState = {
  cart: JSON.parse(sessionStorage.getItem("cart")) || [],
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      return {
        ...state,
        cart: [...state.cart, action.payload],
      };
    case REMOVE_FROM_CART:
      return {
        ...state,
        cart: state.cart.filter((item) => item.id !== action.payload),
      };

    case FETCH_PRODUCT_IMAGES_SUCCESS:
      const updatedCart = state.cart.map((cartItem) => {
        const matchingImage = action.payload.find((image) => image.internal_code === cartItem.id);
        return {
          ...cartItem,
          imageUrl: matchingImage ? matchingImage.image : '',
          imageType: matchingImage ? matchingImage.image_type : '',
        };
      });

      // console.log(updatedCart)

      return { ...state, cart: updatedCart };
    
    case RESET_CART_ITEMS:
      return{
        ...state,
        cart: []
      }

    default:
      return state;
  }
};

export default cartReducer;
