export const reviews = [
  {
    review: "منتجات المكملات الغذائية من هذا المتجر رائعة! لقد لاحظت تحسنًا كبيرًا في مستوى طاقتي وأدائي في التمارين. خدمة العملاء ممتازة والتوصيل سريع. بالتأكيد سأكون عميلاً دائمًا",
    clientName: "أحمد خالد",
  },

  {
    review: "جودة المنتجات عالية جدًا والأسعار معقولة. كنت أتمنى لو كان هناك المزيد من العروض والخصومات. ومع ذلك، أنا راضية تمامًا عن الشراء وسأعود للشراء مرة أخرى",
    clientName: "فاطمة علي",
  },

  {
    review: "أفضل متجر مكملات غذائية تعاملت معه حتى الآن. لدي مشاكل في الهضم واستخدمت البروبيوتيك الذي اشتريته من هنا وشعرت بتحسن ملحوظ. أنصح الجميع بتجربة منتجاتهم",
    clientName: "سمية",
  },

  {
    review: "المنتجات جيدة لكن التغليف كان به بعض الأضرار عند الاستلام. خدمة العملاء كانوا متعاونين واستبدلوا المنتج بسرعة. أتمنى أن يهتموا أكثر بجودة التغليف في المستقبل",
    clientName: "محمد عبدالمنعم",
  },

];
