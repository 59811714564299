import React from "react";
import "./primaryButton.css";

const PrimaryButton = ({ handleClick, text }) => {

  return (
    <div>
      <button className={`primaryButton`}  onClick={handleClick}>
        {text}
      </button>
    </div>
  );
};

export default PrimaryButton;
