import React, { useEffect, useState } from "react";
import CardDataStats from "../../components/CardDataStats";
import ChartOne from "../../components/Charts/ChartOne";
import axios from "axios";
import { config } from "../../config";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { CalculateViews } from "../../utilities/calculateViews";
import { Wallet } from "@mui/icons-material";

const Statistcs2 = () => {
  const [statistics, setStatistics] = useState({
    totalOrder: 0,
    ordersPaid: 0,
    ordersUnPaid: 0,
  });

  const[wallet, setWallet] = useState(0)

  const [chartState, setChartState] = useState([
    {
      name: "orders",
      data: [],
    },
  ]);

  const [chartPlaceState, setChartPlaceState] = useState([]);
  const token = Cookies.get("jwt");
  const user = jwtDecode(token);

  useEffect(() => {
    axios
      .get(config.baseURL + "Orders/getStatistics", {
        params: {
          id: +user.userId,
        },
      })
      .then((res) => {
        setStatistics({
          totalOrder: CalculateViews(res.data.monthlyStatisitcs),
          ordersPaid: res.data.ordersPaid,
          ordersUnPaid: res.data.ordersUnPaid,
        });
        setChartState([
          {
            name: "orders",
            data: res.data.monthlyStatisitcs,
          },
        ]);
      });

    axios
      .get(config.baseURL + "Marketers/getMarketerDues", {
        params: {
          user_id: +user.userId,
        },
      })
      .then((res) => {
        setWallet(+res.data[0].total_commission - +res.data[0].total_shipping)
      });
  }, []);

 //console.log(statistics)

  return (
    <>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5">
        <CardDataStats
          title="المحفظة"
          total={wallet.toString()}
          rate=""
          levelUp
        >
          <svg
            className="fill-primary dark:fill-white" // Same class for color
            width="22" // Adjusted width to match the second SVG
            height="16" // Adjusted height to match the second SVG
            viewBox="0 0 24 24" // Keep the same viewBox for scaling
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 4h6v6H4V4zm0 10h6v6H4v-6zm10-10h6v6h-6V4zm0 10h6v6h-6v-6z"
              fill="currentColor" // Use currentColor to inherit the color from the class
            />
          </svg>
        </CardDataStats>
        <CardDataStats
          title="عدد الاوردرات"
          total={statistics.totalOrder.toString()}
          rate=""
          levelUp
        >
          <svg
            className="fill-primary dark:fill-white" // Same class for color
            width="22" // Adjusted width to match the second SVG
            height="16" // Adjusted height to match the second SVG
            viewBox="0 0 24 24" // Keep the same viewBox for scaling
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 4h6v6H4V4zm0 10h6v6H4v-6zm10-10h6v6h-6V4zm0 10h6v6h-6v-6z"
              fill="currentColor" // Use currentColor to inherit the color from the class
            />
          </svg>
        </CardDataStats>
        <CardDataStats
          title="اوردرات تم استلام دفعتها"
          total={statistics.ordersPaid.toString()}
          rate=""
          levelUp
        >
          <svg
            className="fill-primary dark:fill-white" // Same class for color
            width="22" // Adjusted width to match the second SVG
            height="16" // Adjusted height to match the second SVG
            viewBox="0 0 24 24" // Keep the same viewBox for scaling
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M3 21h18M3 10h3v11H3zm6 0h3v11H9zm6 0h3v11h-3zm-9-5h3v3H6zm6 0h3v3h-3zm6 0h3v3h-3zM3 4h18v3H3z" />
          </svg>
        </CardDataStats>
        <CardDataStats
          title="اوردرات لم يتم استلام دفعتها"
          total={statistics.ordersUnPaid.toString()}
          rate="2.59%"
          levelUp
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="fill-current"
          >
            <path d="M20.71 7.29a1 1 0 0 0 0-1.42l-5.29-5.29a1 1 0 0 0-1.42 0l-11 11a1 1 0 0 0-.29.71v4a1 1 0 0 0 1 1h4a1 1 0 0 0 .71-.29l11-11zM14 2l6 6-11 11H8v-4l11-11z" />
            <path d="M16 8a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
          </svg>
        </CardDataStats>
      </div>

      <div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
        <ChartOne
          chartState={chartState}
          text="Views"
          title="احصائيات هذه السنة"
        />
        {/* <ChartTwo /> */}
        {/* <ChartOne
          chartState={chartPlaceState}
          text="Clicks"
          title="Top 2 Places clicks"
        /> */}
        {/* <ChartThree /> */}
        {/* <MapOne /> */}
        <div className="col-span-12 xl:col-span-8">{/* <TableOne /> */}</div>
        {/* <ChatCard /> */}
      </div>
    </>
  );
};

export default Statistcs2;
