import "./App.css";
import { BrowserRouter } from "react-router-dom";
import MainContent from "./MainContent";

function App() {
  return (
    // <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <MainContent />
    </BrowserRouter>
    // </QueryClientProvider>
  );
}

export default App;
