import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./companies.css";
import axios from "axios";
import { config } from "../../config";
import { Link } from "react-router-dom";

const Companies = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    setCompanies([]);

    axios.get(config.baseURL + "Companies/getCompanies").then((response) => {
      const companiesWithImages = response.data.map((company) => ({
        ...company,
        image: `data:${company.image_type};base64,${company.image}`,
      }));

      setCompanies(companiesWithImages);
    });
    // Update categories with base64-encoded image data
  }, []);

  return (
    <div className="companies_section">
      <h2 className="companies_header">{t("companies")}</h2>
      <div class="company-grid">
        {companies.map((company) =>
          company.name !== "بدون شركة" ? (
            <Link
              to={`/company/${company.id}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div class="company-card">
                <div class="company-image">
                  <img
                    src={company.image}
                    alt={company.name}
                    class="company-logo"
                  />
                  <div class="overlay">
                    <div class="text">
                      {i18n.language === "en" ? company.name_en : company.name}
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ) : null
        )}
      </div>
    </div>
  );
};

export default Companies;
