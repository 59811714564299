import React, { useContext } from "react";
import "./productCard.css";
import PrimaryButton from "../button/primaryButton";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { addToCart, removeFromCart } from "../../redux/cart/actions";
import { useTranslation } from "react-i18next";
import { UpdateImageContext } from "../../context/updateImage";

const ProductCard = ({
  name,
  price,
  image,
  image_type,
  id,
  addToCart,
  removeFromCart,
  isInCart,
}) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { updateImage, setUpdateImage } = useContext(UpdateImageContext);

  return (
    <div
      className="max-w-sm bg-white  rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mr-3 product-card"
      style={{
        margin: "auto",
        backgroundColor: "#222222",
        color: "white",
        boxShadow:
          "0 6px 5px 0 rgba(217, 28, 28, 0.1), 0 6px 5px 0 rgba(217, 28, 28, 0.3)",
      }}
      // This will help center the card
    >
      <Link
        style={{ textDecoration: "none", color: "black" }}
        to={`/productDetails/${id}`}
      >
        <div>
          <img
            className="rounded-t-lg"
            src={`data:${image_type};base64,${image}`}
            alt={name}
            style={{ height: "200px", objectFit: "cover", margin: "0 auto" }} // Set the height and handle the image fit
          />
        </div>
      </Link>
      <div className="p-5">
        <p className="mb- text-lg font-bold text-white-700 dark:text-white-400">
          {String(price).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} EGP
        </p>
        <div>
          <h5 className="text-lg font-bold tracking-tight text-white-900 dark:text-white text-right">
            {name}
          </h5>
        </div>

        {isInCart ? (
          <div
            style={{
              backgroundColor: "#D91C1C",
              cursor: "pointer",
              width: "100%",
            }}
            className="mt-2 inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            onClick={() => removeFromCart(id)}
          >
            <div style={{ textAlign: "center", width: "100%" }}>
              {t("card_delete_from_cart")}
            </div>
          </div>
        ) : (
          <Link
            to={`/productDetails/${id}`}
            className="mt-2 inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            style={{
              backgroundColor: "#D91C1C",
              width: "100%",
              textAlign: "center",
            }}
          >
            <div style={{ textAlign: "center", width: "100%" }}>
              {t("card_add_to_cart")}
            </div>
          </Link>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  isInCart: state.cart.some((item) => item.id === ownProps.id),
});

const mapDispatchToProps = {
  addToCart,
  removeFromCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);
