export const Governates = [
  {
    id: "1",
    governorate_name_ar: "القاهرة",
    governorate_name_en: "Cairo",
    shipping: 45,
  },
  {
    id: "2",
    governorate_name_ar: "الجيزة",
    governorate_name_en: "Giza",
    shipping: 45,
  },
  {
    id: "3",
    governorate_name_ar: "الأسكندرية",
    governorate_name_en: "Alexandria",
    shipping: 60,
  },
  {
    id: "4",
    governorate_name_ar: "الدقهلية",
    governorate_name_en: "Dakahlia",
    shipping: 65,
  },
  {
    id: "5",
    governorate_name_ar: "البحر الأحمر",
    governorate_name_en: "Red Sea",
    shipping: 85,
  },
  {
    id: "6",
    governorate_name_ar: "البحيرة",
    governorate_name_en: "Beheira",
    shipping: 65,
  },
  {
    id: "7",
    governorate_name_ar: "الفيوم",
    governorate_name_en: "Fayoum",
    shipping: 75,
  },
  {
    id: "8",
    governorate_name_ar: "الغربية",
    governorate_name_en: "Gharbiya",
    shipping: 65,
  },
  {
    id: "9",
    governorate_name_ar: "الإسماعلية",
    governorate_name_en: "Ismailia",
    shipping: 60,
  },
  {
    id: "10",
    governorate_name_ar: "المنوفية",
    governorate_name_en: "Menofia",
    shipping: 65,
  },
  {
    id: "11",
    governorate_name_ar: "المنيا",
    governorate_name_en: "Minya",
    shipping: 75,
  },
  {
    id: "12",
    governorate_name_ar: "القليوبية",
    governorate_name_en: "Qaliubiya",
    shipping: 65,
  },
  {
    id: "14",
    governorate_name_ar: "السويس",
    governorate_name_en: "Suez",
    shipping: 60,
  },
  {
    id: "15",
    governorate_name_ar: "اسوان",
    governorate_name_en: "Aswan",
    shipping: 75,
  },
  {
    id: "16",
    governorate_name_ar: "اسيوط",
    governorate_name_en: "Assiut",
    shipping: 75,
  },
  {
    id: "17",
    governorate_name_ar: "بني سويف",
    governorate_name_en: "Beni Suef",
    shipping: 75,
  },
  {
    id: "18",
    governorate_name_ar: "بورسعيد",
    governorate_name_en: "Port Said",
    shipping: 60,
  },
  {
    id: "19",
    governorate_name_ar: "دمياط",
    governorate_name_en: "Damietta",
    shipping: 65,
  },
  {
    id: "20",
    governorate_name_ar: "الشرقية",
    governorate_name_en: "Sharkia",
    shipping: 65,
  },
  {
    id: "22",
    governorate_name_ar: "كفر الشيخ",
    governorate_name_en: "Kafr Al sheikh",
    shipping: 65,
  },
  {
    id: "23",
    governorate_name_ar: "مطروح",
    governorate_name_en: "Matrouh",
    shipping: 85,
  },
  {
    id: "24",
    governorate_name_ar: "الأقصر",
    governorate_name_en: "Luxor",
    shipping: 75,
  },
  {
    id: "25",
    governorate_name_ar: "قنا",
    governorate_name_en: "Qena",
    shipping: 75,
  },
  {
    id: "26",
    governorate_name_ar: "جنوب سيناء",
    governorate_name_en: "South Sinai",
    shipping: 100,
  },
  {
    id: "27",
    governorate_name_ar: "سوهاج",
    governorate_name_en: "Sohag",
    shipping: 75,
  },
];
