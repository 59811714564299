import React from "react";
import './cartButton.css'

const CartButton = () => {
  return (
    <div>
      <button className="cartButton" style={{backgroundColor: "red"}}>إتمام الطلب</button>
    </div>
  );
};

export default CartButton
