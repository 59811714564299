import React, { useState } from "react";
import "./imageZoom.css";
const ImageZoom = ({ src, zoomLevel = 2 }) => {
  const [backgroundPosition, setBackgroundPosition] = useState("0% 0%");
  const [isZoomed, setIsZoomed] = useState(false);

  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;
    setBackgroundPosition(`${x}% ${y}%`);
  };

  const handleMouseEnter = () => {
    setIsZoomed(true);
  };

  const handleMouseLeave = () => {
    setIsZoomed(false);
  };

  return (
    <div
      className="image-zoom-container productDetails_productImage"
      onMouseMove={handleMouseMove}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        position: "relative",
        overflow: "hidden",
      }}
    >
      <img
        src={src}
        alt="Zoom"
        style={{
          width: "100%",
          height: "100%",
        }}
      />
      {isZoomed && (
        <div
          className="zoomed-image"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundImage: `url(${src})`,
            backgroundPosition: backgroundPosition,
            backgroundSize: `${zoomLevel * 100}%`,
            pointerEvents: "none", // To prevent this div from blocking the mouse events on the image
            transition: "background-position 0.1s",
          }}
        />
      )}
    </div>
  );
};

export default ImageZoom;
