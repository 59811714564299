import React, { useEffect, useState } from "react";
import "./checkOut.css";
import NavBar from "../../components/nav-bar/navBar";
import Footer from "../../components/footer/footer";
import { connect } from "react-redux";
import { makeid } from "../../utilities/makeId";
import { Governates } from "../../utilities/governate";
import { CalculateTotal } from "../../utilities/calculateTotal";
import PrimaryButton from "../../components/button/primaryButton";
import axios from "axios";
import { config } from "../../config";
import { useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import "jspdf-autotable";
import logo from "../../assets/warshtyLogo.png";
import { savePDF } from "../../utilities/createPDF";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import { CalculateCommission } from "../../utilities/calculateCommission";
import { toast } from "react-toastify";
import NavBar2 from "../../components/nav-bar2/navBar2";

const moment = require('moment')

const CheckOut = ({ cart }) => {
  const navigate = useNavigate();
  const date = new Date();
  const accessToken = Cookies.get("jwt");
  let userId = 14;
  const [isLoading, setIsLoading] = useState(false);
  if (accessToken) {
    try {
      userId = +jwtDecode(accessToken).userId;
    } catch (error) {
      console.error("Invalid token:", error);
    }
  }
  const [order, setOrder] = useState({
    internal_code: makeid().toString(),
    first_name: "",
    last_name: "",
    phone: "",
    phone2: "",
    email: "",
    d: date,
    date: moment(date).format('YYYY-MM-DDTHH:mm:ss'),
    dd: date,
    deliverDate: moment(date).format('YYYY-MM-DDTHH:mm:ss'),
    governate: "...",
    building_number: "",
    address: "",
    notes: "",
    moderator: 0,
    status: "قيد الانتظار",
    products: cart,
    total: CalculateTotal(cart),
    finalTotal: CalculateTotal(cart),
    paid: 0,
    rest: CalculateTotal(cart),
    add: 0,
    shipping: 0,
    pdf: "",
    pdfLink: "",
    user_id: userId,
    commission_total: CalculateCommission(cart),
  });

  //console.log(order)
  const [governate, setGovernate] = useState("...");

  async function handleClick(e) {
    e.preventDefault();
    //savePDF()
    setIsLoading(true)
    if (
      order.first_name &&
      order.last_name &&
      order.phone &&
      order.governate &&
      order.address &&
      order.products.length
    ) {
      //const pdf = await savePDF(order);
      axios
        .post(config.baseURL + "Orders/addOrder", {
          order: order,
          pdf: "",
        })
        .then((res) => {
          if (res.status === 200) {
            navigate("/succeededPage");
          }
        })
        .catch((err) => {
          //console.log(err)
        });
    } else {
      setIsLoading(false)
      toast.error("برجاء ملئ كل البيانات المطلوبة");
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div className="checkOut-page">
      <div className="body">
        <div className="checkOut-content">
          <div className="receipt-details">
            <h3 className="receipt-details_text">تفاصيل الفاتورة</h3>
            <div className="checkOutDetails-container">
              <div>الاسم الاول</div>
              <div>الاسم الاخير</div>
            </div>

            <div className="checkOutDetails-container">
              <input
                className="checkOut-input"
                style={{ marginLeft: "2rem" }}
                value={order.first_name}
                onChange={(e) =>
                  setOrder({
                    ...order,
                    first_name: e.target.value,
                  })
                }
              />
              <input
                className="checkOut-input"
                value={order.last_name}
                onChange={(e) =>
                  setOrder({
                    ...order,
                    last_name: e.target.value,
                  })
                }
              />
            </div>

            <div style={{ marginTop: "2rem" }}>الهاتف</div>

            <div style={{ display: "flex" }}>
              <input
                className="checkOut-input"
                style={{ width: "100%" }}
                value={order.phone}
                onChange={(e) =>
                  setOrder({
                    ...order,
                    phone: e.target.value,
                  })
                }
              />
            </div>

            <div style={{ marginTop: "2rem" }}>الهاتف الثانى (اختيارى)</div>

            <div style={{ display: "flex" }}>
              <input
                className="checkOut-input"
                style={{ width: "100%" }}
                value={order.email}
                onChange={(e) =>
                  setOrder({
                    ...order,
                    phone2: e.target.value,
                  })
                }
              />
            </div>

            {/* <div style={{ marginTop: "2rem" }}>الدولة / المنطقة</div>

            <div style={{ display: "flex" }}>
              <div style={{ color: "#777777", fontWeight: "bold" }}>مصر</div>
            </div> */}

            <div style={{ marginTop: "2rem" }}>المحافظة</div>

            <div style={{ display: "flex" }}>
              <select
                className="checkOut-input"
                style={{ width: "100%" }}
                onChange={(e) => {
                  if (e.target.value === "") {
                    setOrder({
                      ...order,
                      governate: "",
                      shipping: 0,
                      finalTotal: order.total,
                    });
                  } else {
                    const governate = JSON.parse(e.target.value);

                    setOrder({
                      ...order,
                      governate: governate.governorate_name_ar,
                      shipping: +governate.shipping,
                      finalTotal: +order.total + +governate.shipping,
                    });
                  }
                }}
              >
                <option value="">...</option>
                {/* <option>القاهرة</option>
                  <option>الاسكندرية</option> */}
                {Governates
                  ? Governates.map((gov) => (
                      <option key={gov.id} value={JSON.stringify(gov)}>
                        {gov.governorate_name_ar}
                      </option>
                    ))
                  : null}
              </select>
            </div>

            {/* <div style={{ marginTop: "2rem" }}>رقم الطابق</div>

            <div style={{ display: "flex" }}>
              <input
                className="checkOut-input"
                style={{ width: "100%" }}
                value={order.building_number}
                onChange={(e) =>
                  setOrder({
                    ...order,
                    building_number: e.target.value,
                  })
                }
              />
            </div> */}

            <div style={{ marginTop: "2rem" }}>عنوان الشارع / الحى</div>

            <div style={{ display: "flex" }}>
              <input
                className="checkOut-input"
                style={{ width: "100%" }}
                value={order.address}
                onChange={(e) =>
                  setOrder({
                    ...order,
                    address: e.target.value,
                  })
                }
              />
            </div>

            <div style={{ marginTop: "2rem" }}>ملاحظات الطلب (اختيارى)</div>

            <div style={{ display: "flex" }}>
              <textarea
                className="checkOut-input"
                style={{ width: "100%" }}
                value={order.notes}
                onChange={(e) =>
                  setOrder({
                    ...order,
                    notes: e.target.value,
                  })
                }
              />
            </div>
          </div>

          <div className="receipt">
            <h3 className="receipt_text">طلبك</h3>

            <div className="receiptDetails_content">
              <div className="receiptDetails_content_container">
                <div className="receiptDetails_Text">المنتج</div>
                <div className="receiptDetails_Text">المجموع</div>
              </div>

              {cart
                ? cart.map((item) => (
                    <div className="receiptDetails_content_container marginTop">
                      <div>
                        <div>{item.name}</div>
                        {Object.entries(item.descriptions).map(
                          ([id, descriptions]) => (
                            <div>
                              {descriptions.title}: {descriptions.value}
                            </div>
                          )
                        )}
                      </div>
                      <div>
                        {String(item.finalPrice).replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        )}
                      </div>
                    </div>
                  ))
                : null}

              <div className="receiptDetails_content_container marginTop">
                <div className="receiptDetails_Text">المجموع</div>
                <div className="receiptDetails_Text">
                  {String(order.total).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </div>
              </div>

              <div className="receiptDetails_content_container marginTop">
                <div className="receiptDetails_Text">الشحن</div>
                <div className="receiptDetails_Text">{order.shipping}</div>
              </div>

              <div
                className="receiptDetails_content_container marginTop"
                style={{ border: "none" }}
              >
                <div className="receiptDetails_Text">المجموع</div>
                <div className="receiptDetails_Text">
                  {String(order.finalTotal).replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ","
                  )}
                </div>
              </div>
            </div>

            <div style={{ marginTop: "2rem" }}>
              <button
                className={`primaryButton`}
                onClick={handleClick}
                disabled={isLoading}
              >
                {isLoading ? (
                  <svg
                    className="w-5 h-5 mx-auto animate-spin text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.963 7.963 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : (
                  "تأكيد الطلب"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  cart: state.cart,
});

export default connect(mapStateToProps)(CheckOut);
