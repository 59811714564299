import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./products.css";
import ProductCard from "../productCard/productCard";
import axios from "axios";
import { config } from "../../config";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";

const Products = ({
  categories,
  selectedCategory,
  setSelectedCategory,
  products,
  handleCategoryClick,
}) => {
  // const [categories, setCategories] = useState([]);
  // const [selectedCategory, setSelectedCategory] = useState(null);
  // const [products, setProducts] = useState([]);
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const accessToken = Cookies.get("jwt");
  let user = null;

  if (accessToken) {
    try {
      user = jwtDecode(accessToken);
    } catch (error) {
      //console.error("Invalid token:", error);
    }
  } else {
    //console.log("No token found");
  }
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: 1, // Adjust number of items as needed
    },
  };

  // function handleCategoryClick(category) {
  //   setProducts([]);
  //   setSelectedCategory(category.name);

  //   axios
  //     .get(config.baseURL + `Products/getProductsForHomePage?id=${category.id}`)
  //     .then((response) => {
  //       setProducts(response.data);
  //     });
  // }

  // useEffect(() => {
  //   setCategories([]);

  //   axios.get(config.baseURL + "Categories/getCategories").then((response) => {
  //     const categoriesWithImages = response.data.map((category) => ({
  //       ...category,
  //       image: `data:${category.image_type};base64,${category.image}`,
  //     }));

  //     handleCategoryClick(categoriesWithImages[0]);
  //     setSelectedCategory(categoriesWithImages[0].name);
  //     setCategories(categoriesWithImages);
  //   });

  //   // Update categories with base64-encoded image data
  // }, []);

  return (
    <div className="products_section">
      <div className="products_section__categories">
        {categories
          ? categories.map((category, index) => (
              <div
                key={index}
                className={
                  selectedCategory
                    ? selectedCategory.name === category.name
                      ? "products_section__categoryName selectedCategory"
                      : "products_section__categoryName"
                    : "products_section__categoryName"
                }
                onClick={() => handleCategoryClick(category)}
              >
                {i18n.language === "en" ? category.name_en : category.name}
              </div>
            ))
          : null}
      </div>

      {products.length ? (
        <Carousel responsive={responsive} infinite>
          {products
            ? products.map((product) => (
                <ProductCard
                  name={product.name}
                  price={
                    user
                      ? +user.userType === 1
                        ? product.price
                        : product.marketer_price
                      : product.price
                  }
                  image={product.image}
                  image_type={product.image_type}
                  id={product.internal_code}
                />
              ))
            : null}
        </Carousel>
      ) : (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default Products;
